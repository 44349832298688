<template>
	<div>
		<Header :title="isEdit ? '修改优惠券' : '新增优惠券'" @back="onBack(false)"></Header>
		<a-spin :spinning="loading">
			<a-form style="margin-top: 30px;" ref="form" :model="modelRef" scrollToFirstError name="form"
				:labelCol="{span: 6, xxl: 5}" :wrapperCol="{span: 16, xxl: 15 }">
				<a-row>
					<a-col :span="24">
						<div>
							<h3 class="ui-title">优惠券信息</h3>
							<a-form-item label="券标题" name="title" :rules="[{required: true, message: '必填项不允许为空'}]">
								<a-input v-model:value="modelRef.title" placeholder="请输入券标题"></a-input>
							</a-form-item>
							<a-form-item label="使用说明" name="useRule">
								<a-textarea :autoSize="{ minRows: 5, maxRows: 10 }"
									v-model:value="modelRef.useRule"></a-textarea>
							</a-form-item>
							<a-form-item label="券类型" name="type" :rules="[{required: true, message: '必填项不允许为空'}]">
								<a-radio-group v-model:value="modelRef.type" @change="onCouponTypeChange()">
									<a-radio :value="1">代金券</a-radio>
									<a-radio :value="2">兑换券</a-radio>
								</a-radio-group>
							</a-form-item>
							<a-form-item v-if="[2].includes(modelRef.type)" label="补差金额" name="diffPrice"
								:rules="[{required: true, message: '必填项不允许为空'}]">
								<a-input-number v-model:value="modelRef.diffPrice" :min="0" :precision="2"
									placeholder="请输入补差金额"
									style="width: 200px; border-bottom-left-radius: 10px; border-top-left-radius: 10px"></a-input-number>
								<span class="ui-min">元</span>
							</a-form-item>
							<a-form-item class="ui-form__item" name="useLimit" label="使用场景"
								:rules="[{required: true, message: '必填项不允许为空'}]">
								<a-select v-model:value="modelRef.useLimit" placeholder="请选择使用场景" style="width: 180px;"
									@change="modelRef.policyIds = []">
									<a-select-option :value="1">影片</a-select-option>
									<a-select-option v-if="![2].includes(modelRef.type)" :value="2">演出</a-select-option>
									<a-select-option :value="3">卖品</a-select-option>
									<a-select-option v-if="![2].includes(modelRef.type)" :value="4">商城</a-select-option>
								</a-select>
							</a-form-item>
							<!--              <a-form-item v-if="[1].includes(modelRef.type)" label="满额度可使用" name="isLimitPrice" :rules="[{required: true, message: '必填项不允许为空'}]">-->
							<!--                <a-switch v-model:checked="modelRef.isLimitPrice" />-->
							<!--              </a-form-item>-->
							<!--              <a-form-item v-if="modelRef.isLimitPrice && [1].includes(modelRef.type)" label="额度" name="limitPrice" :rules="[{required: true, message: '必填项不允许为空'}]">-->
							<!--                <a-input v-model:value="modelRef.limitPrice" placeholder="请输入额度" style="width: 200px;"></a-input>-->
							<!--              </a-form-item>-->
							<div>
								<a-form-item v-if="[1].includes(modelRef.type)" label="抵扣金额"
									:rules="[{required: true, message: '必填项不允许为空'}]">
									满 <a-input-number v-model:value="modelRef.limitPrice" placeholder="请输入金额"
										style="width: 100px;" :min="0" :precision="2"></a-input-number><span
										class="ui-min">元</span> 抵扣
									<a-input-number v-model:value="modelRef.deductionPrice" placeholder="请输入金额"
										style="width: 100px;" :min="0" :precision="2"></a-input-number><span
										class="ui-min">元</span>
								</a-form-item>
							</div>
							<a-form-item label="有效期" name="validPeriodType">
								<a-radio-group v-model:value="modelRef.validPeriodType">
									<a-radio :value="1">领取后天数</a-radio>
									<a-radio :value="2">具体日期</a-radio>
								</a-radio-group>
							</a-form-item>
							<a-form-item v-if="modelRef.validPeriodType === 1" label="天数" name="period"
								:rules="[{required: true, message: '必填项不允许为空'}]">
								<a-input-number v-model:value="modelRef.period" placeholder="请输入天数" :min="0"
									:precision="0"
									style="width: 200px; border-bottom-left-radius: 10px; border-top-left-radius: 10px"></a-input-number>
								<span class="ui-min">天</span>
							</a-form-item>
							<a-form-item v-if="modelRef.validPeriodType === 2" label="日期" name="periodDate"
								:rules="[{ required: true, message: '必填项不允许为空' }]">
								<a-range-picker v-model:value="modelRef.periodDate"></a-range-picker>
							</a-form-item>

							<a-form-item label="使用政策" name="policyIds"
								:rules="[{ required: true, message: '必选项不允许为空' }]">
								<a-button v-if="!modelRef.useLimit" disabled>请先选择使用场景</a-button>
								<a-form-item-rest v-else>
									<div>
										<policy v-model:value="modelRef.policyIds" :useLimit="modelRef.useLimit" type="radio"></policy>
									</div>
								</a-form-item-rest>
							</a-form-item>

							<a-form-item label="状态" name="isDisabled">
								<a-radio-group v-model:value="modelRef.isDisabled">
									<a-radio :value="0">启用</a-radio>
									<a-radio :value="1">禁用</a-radio>
								</a-radio-group>
							</a-form-item>
						</div>
					</a-col>
				</a-row>

				<a-row>
					<a-col :span="24" style="margin-top: 20px;text-align: center;">
						<a-button v-if="!isSee" type="primary" html-type="submit" @click="onSubmit">提交</a-button>
						<a-button style="margin-left: 20px;" @click="onBack(false)">返回</a-button>
					</a-col>
				</a-row>
			</a-form>
		</a-spin>
	</div>
</template>

<script>
	import Header from '@/components/header/header.vue';
	import {
		Icon
	} from '@/components/icon/icon.js';
	import policy from '@/components/select/selectPolicy/index.vue';
	import {
		getCouponDetail,
		saveCoupon,
		updateCoupon
	} from "../../../../service/modules/coupon";
	export default {
		components: {
			Header,
			Icon,
			policy
		},
		props: {
			isEdit: {
				type: Boolean,
				default: true
			},
			id: {
				type: Number,
				default: 0
			},
			isSee: {
				type: Boolean,
				default: false
			},
			type: {
				type: String,
				default: ''
			},
		},
		created() {
			if (this.id) {
				this.getData();
			}
		},
		data() {
			return {
				index: 0,
				loading: false,
				modelRef: {
					type: 0,
					policyIds: []
				},
			}
		},
		methods: {
			onBack(isRef) {
				this.$emit('back', isRef);
			},
			onCouponTypeChange() {
				this.modelRef.deductionPrice = '';
				this.modelRef.useLimit = undefined;

			},
			async getData() {
				this.loading = true;
				try {
					let ret = await getCouponDetail({
						id: this.id
					})
					this.loading = false;
					if (ret.code === 200) {
						ret.data.policyIds = ret.data.policyIds ? ret.data.policyIds.split(',').map(item => Number(
							item)) : [];
						if (ret.data.periodStartDate) {
							ret.data.periodStartDate = this.moment(ret.data.periodStartDate * 1000);
						} else {
							ret.data.periodStartDate = undefined;
						}
						if (ret.data.periodEndDate) {
							ret.data.periodEndDate = this.moment(ret.data.periodEndDate * 1000);
						} else {
							ret.data.periodEndDate = undefined;
						}
						if (ret.data.periodStartDate && ret.data.periodEndDate) {
							const timeList = [];
							timeList.push(ret.data.periodStartDate);
							timeList.push(ret.data.periodEndDate);
							ret.data.periodDate = timeList;
						}

						this.modelRef = ret.data;
					}
				} catch (e) {
					this.loading = false;
				}
			},
			async onSubmit() {
				this.$refs.form.validateFields().then(async () => {
					if (this.modelRef.periodDate && this.modelRef.periodDate.length === 2) {
            // this.modelRef.periodStartDate = this.moment(this.modelRef.periodDate[0]).unix();
            // this.modelRef.periodEndDate = this.moment(this.modelRef.periodDate[1]).unix();
						this.modelRef.periodStartDate = this.moment(this.modelRef.periodDate[0]).startOf('days').unix();
						this.modelRef.periodEndDate = this.moment(this.modelRef.periodDate[1]).endOf('days').unix();
					}
          if (this.modelRef.validPeriodType == 1 && this.modelRef.period < 1) {
            return this.$message.warn('有效天数需大于0');
          }
					let postData = JSON.parse(JSON.stringify(this.modelRef));
					if (postData.policyIds.length === 0) {
						return this.$message.warn('请选择使用政策');
					}
					postData.policyIds = postData.policyIds.join(',');
					postData.isLimitPrice = 1;
					if (this.modelRef.date && this.modelRef.date.length === 2) {
						postData.startDate = this.moment(this.modelRef.date[0].startOf('day')).unix();
						postData.endDate = this.moment(this.modelRef.date[1].endOf('day')).unix();
					}

					try {
						let ret;
						this.loading = true;
						if (this.isEdit && this.id) {
							postData.id = this.id;
							ret = await updateCoupon(postData);
						} else {
							ret = await saveCoupon(postData);
						}
						this.loading = false;
						if (ret.code === 200) {
							this.$message.success('操作成功');
							this.onBack(true);
						}
					} catch (e) {
						this.loading = false;
					}
				})
			},
		}
	}
</script>

<style scoped>
	.ui-title {
		position: relative;
		margin: 20px 0 10px;
		padding-left: 10px;
		font-size: 16px;
		font-weight: 600;
	}

	.ui-title::before {
		content: "";
		position: absolute;
		left: 0;
		top: 50%;
		width: 4px;
		height: 20px;
		border-radius: 2px;
		background-color: #1890ff;
		transform: translateY(-50%);
	}

	.ui-min {
		display: inline-block;
		width: 40px;
		height: 31px;
		text-align: center;
		line-height: 31px;
		background-color: #d7d7d7;
		border-bottom-right-radius: 10px;
		border-top-right-radius: 10px;
	}

	.ui-flex {
		display: flex;
	}

	.ui-margin__top20 {
		margin-top: 20px;
	}

	.ui-time__header {
		padding: 10px;
		color: rgba(0, 0, 0, .65);
		background-color: #f5f5f5;
	}

	.ui-time__span {
		padding: 0 10px;
	}

	.ui-time__set {
		padding: 20px 10px;
		border-bottom: solid 1px #eee;
		border-left: solid 1px #eee;
		border-right: solid 1px #eee;
	}

	.ui-title {
		position: relative;
		margin: 20px 0 20px 80px;
		padding-left: 10px;
		font-size: 16px;
		font-weight: 600;
	}

	.ui-title::before {
		content: "";
		position: absolute;
		left: 0;
		top: 50%;
		width: 4px;
		height: 20px;
		border-radius: 2px;
		background-color: #1890ff;
		transform: translateY(-50%);
	}
</style>
